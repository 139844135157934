<template>
  <b-row style="background-color: #F5F7FA; padding-bottom: 50px;" class="d-flex justify-content-center content">
    <b-col style="max-width: 1200px; min-height: 800px;">
      <b-row>

        <b-col lg="8" offset-lg="2">

          <h1 v-if="newsletter.newsletter_id"
            style="font-size: 20px; margin-left: 5px; margin-bottom: 15px; font-weight: 600;">{{ staticDisplayName }}
          </h1>
          <h1 v-else style="font-size: 20px; margin-left: 5px; margin-bottom: 15px; font-weight: 600;">New Newsletter
          </h1>
          <b-card style="box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);" no-body>
            <b-card-body>
              <b-form @submit.prevent="saveNewsletter" @reset.prevent="onReset">

                <!-- URL -->
                <b-form-group id="url-group" label="Newsletter URL:" label-for="url" description="">
                  <p v-show="!validation.url" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please enter a valid url. Maybe like this:<br><strong>https://hub.jdpower.com/edition-6</strong>
                  </p>
                  <b-form-input id="url" :class="{ 'error': !validation.url}" style="max-width: 450px;"
                    v-model="newsletter.url" placeholder="" trim></b-form-input>
                </b-form-group>

                <b-button v-show="!processing" type="submit" class="btn btn-primary btn-dash-submit float-right">Save
                </b-button>
                <b-button v-show="processing" disabled type="submit"
                  class="btn btn-primary btn-dash-submit float-right">
                  <b-spinner class="mr-2" small></b-spinner> Saving...
                </b-button>
                <b-button @click="cancel" class="btn btn-light float-right mr-3">Cancel</b-button>

              </b-form>

            </b-card-body>
          </b-card>

        </b-col>
      </b-row>
    </b-col>
  </b-row>

</template>

<script>

export default {
  name: 'edit-newsletter',
  components: {

  },

  data() {
    return {
      processing: false,
      newsletter: {
        url: '',
      },
      validation: {
        url: true,
      }
    }
  },

  metaInfo() {
    return {
      title: 'New Newsletter'
    }
  },

  methods: {

    getNewsletter(){
      // we currenly support adding and deleting newsletter urls... no editing!
    },

    saveNewsletter() {
      this.$store.commit("generateApiCreds");
      const self = this;

      if(!this.validateForm()){
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        return false;
      }

      this.processing = true;

      const formData = new FormData();

      // Is this an existing newsletter? If so we need to set our id.
      // if(this.newsletter.newsletter_id){
      //   formData.append('newsletter_id', this.newsletter.newsletter_id);
      // }

      formData.append('user_id', this.$store.state.auth.userId);
      formData.append('token', this.$store.state.auth.token);
      formData.append('url', this.newsletter.url);

      this.$axios
        .post(process.env.VUE_APP_API_PATH + "newsletters", formData, {
            auth: {
              username: this.$store.state.auth.apiUsername,
              password: this.$store.state.auth.apiPassword,
            },
          })
        .then(function() {
          self.processing = false;
          self.$router.go(-1);
        })
        .catch(function() {
          // alert('We have an error on our site. Please contact support and let us know. Thanks!')
          // console.log(resp);
          // alert('error');
        });
    },

    validateForm() {
      try {
        let success = Boolean(new URL(this.newsletter.url));
        if (success === true) {
          return true;
        }
      }
      catch (e) {
        this.validation.url = false;
        return false;
      }
    },

    cancel() {
      this.$router.go(-1);
    },

  },

  computed: {

  },

}
</script>

<style scoped>

.btn-dash-submit {
  background-color: #007cc0;
  border-color: #007cc0;
}

.btn-dash-submit:hover {
  background-color: rgb(23, 92, 142);
  border-color: rgb(23, 92, 142);
}

.error {
  border: 2px solid #d9534f;
}

.btn.disabled {
  cursor:not-allowed;
}

.content {
  padding-top: 20px;
}

@media screen and (max-width: 768px) {

  .content {
    margin-top: 0px;
    padding-top: 40px;
  }

}

</style>
